import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from 'next/router';
import "react-tabs/style/react-tabs.css";
import Meta from "../../components/Meta";
import { useAccount, useConnect, useSignMessage, useDisconnect, useNetwork } from 'wagmi'
import { useWeb3Modal } from "@web3modal/react";
import axios from 'axios';
import { signIn } from 'next-auth/react';
import CustomButton from "../../components/CustomButton";
import LoginButton from "../../components/LoginButton";

import { Web3Button, Web3Modal, Web3NetworkSwitch } from '@web3modal/react'

const Login = () => {
  const { connectors, error, isLoading, pendingConnector } =
    useConnect()
    const { push } = useRouter();
    const { disconnectAsync } = useDisconnect();
    const { signMessageAsync } = useSignMessage();
    const { address, isConecting, isDisconnected, isSignedIn, isConnected } = useAccount();
    const { chain, chains } = useNetwork();
    const { open, close } = useWeb3Modal();

    const connect = useConnect({
      onSettled(data, error) {
        console.log('Settled', { data, error })
      },
      onSuccess(data) {
          console.log('Connect', data)
        },
    })


    async function onOpen() {
      setLoading(true);
      await open();
      setLoading(false);
    }
  
    function onClick() {
      if (isConnected) {
        disconnect();
      } else {
        onOpen();
      }
    }

    const handleAuth = async () => {
      //disconnects the web3 provider if it's already active
      await open();
      await close();
      if (isConnected) {
        console.log(chain);

        const userData = { address: address, chain: chain.id, network: 'evm' };
        // making a post request to our 'request-message' endpoint
        const { data } = await axios.post('/api/auth/request-message', userData, {
          headers: {
            'content-type': 'application/json',
          },
        });
        const message = data.message;
        // signing the received message via metamask
        const signature = await signMessageAsync({ message });

        const { url } = await signIn('credentials', { message, signature, redirect: false, callbackUrl: '/' });
        console.log(url);
        push(url);

        console.log(signature)

      }
      
    }

    return (
      <div>
        <Meta title="Login || CryptoCult" />
        {/* <!-- Login --> */}
        <section className="relative h-screen">
          <div className="lg:flex lg:h-full">
            {/* <!-- Left --> */}
            <div className="relative text-center lg:w-1/2">
              <img
                src="/images/login.jpg"
                alt="login"
                className="absolute h-full w-full object-cover"
              />
              {/* <!-- Logo --> */}
              <Link href="/" className="relative inline-block py-36">

                <img
                  src="/images/CryptoCult_Horizontal_White.png"
                  className="inline-block max-h-7"
                  alt="CryptoCult | Don't Drink the Kool-Aid"
                />

              </Link>
            </div>

            {/* <!-- Right --> */}
            <div className="relative flex items-center justify-center p-[10%] lg:w-1/2">
              <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                <img
                  src="/images/gradient_light.jpg"
                  alt="gradient"
                  className="h-full w-full"
                />
              </picture>

              <div className="w-full max-w-[25.625rem] text-center">
                <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white">
                  Sign in
                </h1>
                <CustomButton />
                <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
                  Choose one of available wallet providers or create a new wallet.
                  <a href="#" className="text-accent">
                    What is a wallet?
                  </a>
                </p>

                <div className="tab-pane fade show active">
                  <LoginButton />
                {/* <Web3Button icon="show" label="Connect Wallet" balance="false" onClick={() => handleAuth()} /> */}

                  {/* {connectors.map((connector) => (
                    <button
                      disabled={!connector.ready}
                      key={connector.id}
                      onClick={() => handleAuth(connector)}
                      className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <img
                        src="/images/wallets/wallet_connect_24.svg"
                        className="mr-2.5 inline-block h-6 w-6"
                        alt=""
                      />
                      {connector.name}
                      {!connector.ready && ' (unsupported)'}
                      {isLoading &&
                        connector.id === pendingConnector?.id &&
                        ' (connecting)'}
                    </button>
                  ))} */}

                  {error && <div>{error.message}</div>}
                </div>



              </div>
            </div>
          </div>
        </section>
        {/* <!-- end login --> */}
      </div>
    );
  };


  export default Login;
