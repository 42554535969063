import { useWeb3Modal } from '@web3modal/react'
import { useState } from 'react'
import { useSignMessage, useDisconnect, useAccount, useConnect } from 'wagmi'
import axios from 'axios';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';


function CustomButton() {
  const { push } = useRouter();
  const { signMessageAsync } = useSignMessage();
  const [loading, setLoading] = useState(false)
  const { open } = useWeb3Modal()
  const { isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const { connectAsync } = useConnect();
  const { connectors, error, isLoading, pendingConnector } =
  useConnect()

  async function onOpen() {
    console.log('open it');
    setLoading(true)
    console.log('opened');
    await open()
    if (isConnected) {
      await disconnectAsync();
    }
    console.log('progress');
    console.log(connectors[0]);
    setLoading(false)
    console.log('set loading false');
    const { account, chain } = await connectAsync({
      connector: connectors[0]
    });
    console.log('connect async');

    console.log(account);
    console.log(chain);

    const userData = { address: account, chain: chain.id, network: 'evm' };
    console.log(`user data:`);
    console.log(userData);
      // making a post request to our 'request-message' endpoint
      const { data } = await axios.post('/api/auth/request-message', userData, {
        headers: {
          'content-type': 'application/json',
        },
      });
      const message = data.message;
      // signing the received message via metamask
      const signature = await signMessageAsync({ message });

      const { url } = await signIn('credentials', { message, signature, redirect: false, callbackUrl: '/' });
      push(url);

      console.log(signature)

  }

  return (
    <button onClick={onOpen} disabled={loading}>
      {loading ? 'Loading' : 'None'}
    </button>
  )
}

export default CustomButton