import { useWeb3Modal } from "@web3modal/react";
import { useState } from "react";
import { useAccount, useDisconnect, useConnect , useNetwork, useSignMessage} from "wagmi";
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import axios from 'axios';

export default function CustomButton() {
    const [loading, setLoading] = useState(false);
    const [securityCheck, setSecurityCheck] = useState(false);
    const { open } = useWeb3Modal();
    const { disconnect } = useDisconnect();
    const { chain } = useNetwork();
    const { address, isConecting, isDisconnected, isSignedIn } = useAccount();
    const { signMessageAsync } = useSignMessage();
    const { push } = useRouter();

    const { isConnected } = useAccount({
        address(data) {
            console.log('Connect', data)
        }
    });
    const label = isConnected ? "Disconnect" : "Connect Custom";


    if (isConnected) {
        if (!securityCheck) {
            console.log('connected now. process security');
            console.log(chain);
            setSecurityCheck(true);
            processLogin();
        }

    }

    async function processLogin() {
        const userData = { address: address, chain: chain.id, network: 'evm' };
        console.log(userData);
        //making a post request to our 'request-message' endpoint
        const { data } = await axios.post('/api/auth/request-message', userData, {
            headers: {
                'content-type': 'application/json',
            },
        });
        const message = data.message;
        // signing the received message via metamask
        const signature = await signMessageAsync({ message });

        const { url } = await signIn('credentials', { message, signature, redirect: false, callbackUrl: '/' });
        console.log(url);
        push(url);
        setSecurityCheck(false);

         console.log(signature)
    }






    async function onOpen() {
        setLoading(true);
        console.log('loading true')
        await open();
        console.log('finished open');
        setLoading(false);
        console.log('setLoading(false);')
    }


    function onClick() {
        if (isConnected) {
            console.log('connected');
            disconnect();
        } else {
            console.log('calling onOpen');
            onOpen();
        }
    }

    return (
        <button onClick={onClick} disabled={loading}>
            {loading ? "Loading..." : label}
        </button>
    );
}